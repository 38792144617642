<template>
  <div>
    <layout-header/>
    <router-view v-bind:key="pageName"/>
  </div>
</template>

<script>
import layoutHeader from "@/components/layout/layoutHeader";

export default {
  name: "LayoutClient",
  components: {layoutHeader},
  computed:{
    pageName(){
      return this.$route.fullPath || "home"
    }
  },
}
</script>

<style scoped>

</style>
