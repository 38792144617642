export default {
    namespaced: true,

    getters: {
        fileTags() {
            return [
                {value: "Stromvertrag", title: "Stromvertrag", icon: "fas fa-file-contract"},
                {value: "Grundbuchauszug", title: "Grundbuchauszug", icon: "fas fa-file-contract"},
                {value: "Kundenrechnung", title: "Kundenrechnung", icon: "fas fa-file-contract"},
                {value: "Kommissionsrechnung", title: "Kommissionsrechnung", icon: "fas fa-file-contract"},
                {value: "Managementrechnung", title: "Managementrechnung", icon: "fas fa-file-contract"},
                {value: "Heiz/Gasvertrag", title: "Heiz/Gasvertrag", icon: "fas fa-file-contract"},
                {value: "Flächenwidmungsplan", title: "Flächenwidmungsplan", icon: "fas fa-file-contract"},
                {value: "Bauplan", title: "Bauplan", icon: "fas fa-file-contract"},
                {value: "Internetrechnung", title: "Internetrechnung", icon: "fas fa-file-contract"},
                {value: "Reinigungskosten", title: "Reinigungskosten", icon: "fas fa-file-contract"},
                {value: "Flat-Introduction", title: "Flat-Introduction", icon: "fas fa-file-contract"},
                {value: "CheckIn details", title: "CheckIn details", icon: "fas fa-file-contract"},
                {value: "General information", title: "General information", icon: "fas fa-file-contract"},
                {value: "Sonstiges", title: "Sonstiges", icon: "fas fa-file-contract"},
            ]
        },
        allStatus() {
            return [
                {value: "NEW", text: 'NEW'},
                {value: 'ACTIVE', text: 'ACTIVE'},
                {value: 'CANCELED', text: 'CANCELED'},
                {value: 'COMPLETED', text: 'COMPLETED'}
            ]
        },
        statusData() {
            let statusData = {
                "NEW": {variant: "info", value: "contract.status.new", key: "NEW"},
                "REVIEWING": {variant: "warning", value: "contract.status.reviewing", key: "REVIEWING"},
                "WAITING_FOR_DATA": {
                    variant: "warning",
                    value: "contract.status.waiting_for_data",
                    key: "WAITING_FOR_DATA"
                },
                "WAITING_FOR_FILE": {
                    variant: "warning",
                    value: "contract.status.waiting_for_file",
                    key: "WAITING_FOR_FILE"
                },
                "ACTIVE": {variant: "success", value: "contract.status.active", key: "ACTIVE"},
                "COMPLETED": {variant: "success", value: "contract.status.completed", key: "COMPLETED"},
                "CANCELED": {variant: "danger", value: "contract.status.cancelled", key: "CANCELED"},
                "CANCELLED": {variant: "danger", value: "contract.status.cancelled", key: "CANCELLED"},

            }

            return (status) => statusData[status] ? statusData[status] : {
                variant: "info",
                value: `contract.status.${status.toLowerCase()}`,
                key: status
            }
        },
    },
    actions: {
        getById(actions, id) {
            let $this = this;
            return new Promise(function (resolve, reject) {
                $this.$api.clientApi().get('/real-estate/owner/contract/get/' + id)
                    .then(response => {
                        resolve(response.data.objects)
                    })
                    .catch(error => reject(error))
            });
        },

        getAll(actions, {page, amount, filter, sortcolumn, sortby}) {
            let $this = this;
            const queryParams = {
                page: page,
                amount: amount,
                sortcolumn: sortcolumn,
                sortby: sortby,
            };

            return new Promise(function (resolve, reject) {
                $this.$api.clientApi().post('/real-estate/owner/contract/all', filter, {
                    params: queryParams, // Include the query parameters here
                })
                    .then(response => {
                        resolve(response.data.objects)
                    })
                    .catch(error => reject(error))
            });
        },
        getDocument(actions, {fileId, contractId}) {
            let $this = this;
            return new Promise(function (resolve, reject) {
                $this.$api.downloadApi().get(`/real-estate/owner/contract/download/file`, {
                    params: {
                        id: fileId,
                        contractId
                    }
                })
                    .then(response => {
                        console.log("downloadFile", response)
                        let contentType = response.headers["content-type"];
                        let fileName = "file";
                        if (contentType.includes("filename="))
                            fileName = contentType.substr(contentType.toString().indexOf("filename=") + 9)
                        resolve({data: response.data, fileName});
                    }, error => {
                        reject(error);
                    });
            });
        },

        updateContract(action, {id, contract}) {
            let $this = this;
            return new Promise(function (resolve, reject) {
                $this.$api.clientApi().post('/real-estate/owner/contract/update/contract/' + id, contract).then(
                    (response) => {
                        let data = response.data.objects;
                        if (data["contract"] == null)
                            return reject('Could not update contract');
                        action.dispatch('notifications/success', {
                            title: {text: 'Contract %s', arguments: ['details']},
                            message: {text: '%s updated successfully', arguments: ['Details']}
                        }, {root: true});
                        resolve(data["contract"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
        approveContract(action, {id}) {
            let $this = this;
            return new Promise(function (resolve, reject) {
                $this.$api.clientApi().post('/real-estate/owner/contract/approve/' + id).then(
                    (response) => {
                        let data = response.data.objects;
                        if (data["contract"] == null)
                            return reject('Could not approve contract');
                        action.dispatch('notifications/success', {
                            title: {text: 'Contract %s', arguments: ['details']},
                            message: {text: '%s updated successfully', arguments: ['Details']}
                        }, {root: true});
                        resolve(data["contract"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
        rejectContract(action, {id, note}) {
            let $this = this;
            return new Promise(function (resolve, reject) {
                $this.$api.clientApi().post('/real-estate/owner/contract/reject/' + id, {note}).then(
                    (response) => {
                        let data = response.data.objects;
                        if (data["contract"] == null)
                            return reject('Could not reject contract');
                        action.dispatch('notifications/success', {
                            title: {text: 'Contract %s', arguments: ['details']},
                            message: {text: '%s updated successfully', arguments: ['Details']}
                        }, {root: true});
                        resolve(data);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
        cancelContract(action, {id, note}) {
            let $this = this;
            return new Promise(function (resolve, reject) {
                $this.$api.clientApi().post('/real-estate/owner/contract/cancel/' + id, {note}).then(
                    (response) => {
                        let data = response.data.objects;
                        if (data["contract"] == null)
                            return reject('Could not cancel contract');
                        action.dispatch('notifications/success', {
                            title: {text: 'Contract %s', arguments: ['details']},
                            message: {text: '%s updated successfully', arguments: ['Details']}
                        }, {root: true});
                        resolve(data);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
        getContract(actions, contractId) {
            let $this = this;
            return new Promise(function (resolve, reject) {
                $this.$api.downloadApi().get('/real-estate/owner/contract/generate/' + contractId)
                    .then(response => {
                        console.log("downloadFile", response)
                        let contentType = response.headers["content-type"];
                        let fileName = "file";
                        if (contentType.includes("filename="))
                            fileName = contentType.substr(contentType.toString().indexOf("filename=") + 9)
                        resolve({data: new Blob([response.data], {type: 'application/pdf'}), fileName, contentType});
                    }, error => {
                        reject(error);
                    });
            });
        },
        signContract(action, {id, signature}) {
            let $this = this;
            return new Promise(function (resolve, reject) {
                $this.$api.clientApi().post('/real-estate/owner/contract/sign/' + id, {signature}).then(
                    (response) => {
                        let data = response.data.objects;
                        if (data["contract"] == null)
                            return reject('Could not sign contract');
                        action.dispatch('notifications/success', {
                            title: {text: 'Contract %s', arguments: ['details']},
                            message: {text: '%s updated successfully', arguments: ['Details']}
                        }, {root: true});
                        resolve(data);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
        createOrFetchKsv(action, id) {
            let $this = this;
            return new Promise(function (resolve, reject) {
                $this.$api.clientApi().post('/real-estate/owner/contract/fetchOrCreateKsv/' + id, null).then(
                    (response) => {
                        let data = response.data.objects;
                        if (data["Ksv"] == null)
                            return reject('Could not generate and send link');
                        action.dispatch('notifications/success', {
                            title: {text: 'Ksv %s', arguments: ['request']},
                            message: {text: '%s requested successfully', arguments: ['Ksv']}
                        }, {root: true});
                        resolve(data);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },

    }
}
