export default {
    namespaced: true,

    getters: {},
    actions: {

        getTreasureCalendars(actions, filter) {
            let $this = this;
            if (filter == null || typeof filter != "object")
                filter = {};
            return new Promise(function (resolve, reject) {
                $this.$api.clientApi().post('/real-estate/owner/calendar/get', filter)
                    .then(response => {
                        resolve(response.data.objects["Calendars"])
                    })
                    .catch(error => reject(error))
            })

        }

    }
}
