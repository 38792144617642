export default {
    namespaced: true,

    getters: {},
    actions: {

        getDashboard(actions, {dashboardFilter}) {
            let $this = this;

            return new Promise(function (resolve, reject) {
                $this.$api.clientApi().post('/real-estate/owner/dashboard/get', dashboardFilter).then(
                    (response) => {
                        let data = response.data.objects;
                        if (data["dashboard"] == null)
                            return reject('Could not get data ');
                        resolve({
                            payments: data["dashboard"]['payments'],
                            relevantContracts: data["dashboard"]['relevantContracts'],
                            internalMonetaryContracts: data["dashboard"]['internalMonetaryContracts'],
                            paymentCounts: data["dashboard"]['paymentCounts'],
                            totalPayments: data["dashboard"]['totalPayments'],
                            totalPaymentsExtern: data["dashboard"]['totalPaymentsExtern'],
                            totalPaymentsIntern: data["dashboard"]['totalPaymentsIntern'],
                            chartExternal: data["dashboard"]['chartExternal'],
                            externalPayments: data["dashboard"]['externalPayments'],
                            internalPayments: data["dashboard"]['internalPayments'],
                            expenses: data["dashboard"]['expenses'],
                            completed: data["dashboard"]['completed'],
                            active: data["dashboard"]['active'],
                            pending: data["dashboard"]['pending'],
                            clients: data["dashboard"]['clients'],
                        })
                    }, (error) => {
                        reject(error.message)
                    })
            })

        },
        requestInvoice(actions, {dashboardFilter}) {
            let $this = this;
            if (dashboardFilter.month === null || dashboardFilter.year === null || dashboardFilter.realEstateId === null)
            {
                console.log("in if")

                actions.dispatch('notifications/error', {
                    title: {text: 'Requesting invoice', arguments: []},
                    message: {text: 'RealEstate/Month/Year fields are required', arguments: []}
                }, {root: true});
                return null;
            }
            console.log("passed")

            return new Promise(function (resolve, reject) {
                $this.$api.clientApi().post('/real-estate/owner/dashboard/invoice', dashboardFilter).then(
                    (response) => {
                        let data = response.data.objects;
                        actions.dispatch('notifications/success', {
                            title: {text: 'Requesting invoice', arguments: []},
                            message: {text: '%s requested successfully', arguments: ['Invoices']}
                        }, {root: true});
                        resolve(data["invoice"]);
                    }, (error) => {
                        actions.dispatch('notifications/error',
                            {
                                title: 'Requesting invoice',
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })

        },

    }
}
