import Vue from 'vue';
import { ValidationProvider, ValidationObserver, localize, extend } from 'vee-validate';
import en from 'vee-validate/dist/locale/en.json';
import de from 'vee-validate/dist/locale/de.json';
import es from 'vee-validate/dist/locale/es.json';
import he from 'vee-validate/dist/locale/he.json';
import pt_BR from 'vee-validate/dist/locale/pt_BR.json';

extend('one_of_them', (value, values) => {
    console.log('vee-validate -> one_of',{value, values})
    return values.filter(v=>v!= null && v.toString().trim() > 0).length > 0;
});

// Install locales.
localize({ en, de, es, he, pt_BR });

require('../utils/validations/validations')
require('../utils/validations/validators')

// Register it globally
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
