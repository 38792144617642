<template>
  <div id="app" dir="ltr">
    <notification-listener/>
    <router-view/>
  </div>
</template>

<script>

import NotificationListener from '@/components/libs/toastification/NotificationListener'

export default {
  name: 'App',
  components: {NotificationListener}
}
</script>

<style lang="scss">
@import "./assets/scss/core";
</style>
