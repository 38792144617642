export default {
    namespaced: true,

    getters: {

        fileTags() {
            return [
                {value: "Stromvertrag", title: "Stromvertrag", icon: "fas fa-file-contract"},
                {value: "Grundbuchauszug", title: "Grundbuchauszug", icon: "fas fa-file-contract"},
                {value: "Kundenrechnung", title: "Kundenrechnung", icon: "fas fa-file-contract"},
                {value: "Kommissionsrechnung", title: "Kommissionsrechnung", icon: "fas fa-file-contract"},
                {value: "Managementrechnung", title: "Managementrechnung", icon: "fas fa-file-contract"},
                {value: "Heiz/Gasvertrag", title: "Heiz/Gasvertrag", icon: "fas fa-file-contract"},
                {value: "Flächenwidmungsplan", title: "Flächenwidmungsplan", icon: "fas fa-file-contract"},
                {value: "Bauplan", title: "Bauplan", icon: "fas fa-file-contract"},
                {value: "Internetrechnung", title: "Internetrechnung", icon: "fas fa-file-contract"},
                {value: "Reinigungskosten", title: "Reinigungskosten", icon: "fas fa-file-contract"},
                {value: "Flat-Introduction", title: "Flat-Introduction", icon: "fas fa-file-contract"},
                {value: "CheckIn details", title: "CheckIn details", icon: "fas fa-file-contract"},
                {value: "General information", title: "General information", icon: "fas fa-file-contract"},
                {value: "Sonstiges", title: "Sonstiges", icon: "fas fa-file-contract"},
            ]
        },
        allStatus() {
            return [
                {value: "NEW", text: 'NEW'},
                {value: 'EXPOSE', text: 'EXPOSE'},
                {value: 'BAUGENEHMIGUNG_UND_EXPOSE', text: 'BAUGENEHMIGUNG UND EXPOSE'},
                {value: 'BAUSTART_UND_ROHBAU', text: 'BAUSTART UND ROHBAU'},
                {value: 'ROHBAU_UND_INSTALLATIONEN', text: 'ROHBAU UND INSTALLATIONEN'},
                {value: 'BAUARBEITEN_ABGESCHLOSSEN', text: 'BAUARBEITEN ABGESCHLOSSEN'},
                {value: 'SCHLUESSELFERTIG', text: 'SCHLUESSELFERTIG'},
                {value: 'NEUBAU', text: 'NEUBAU'},
                {value: 'ALTBAU', text: 'ALTBAU'}]
        },

        statusVariants() {
            let statusVariants = {
                "NEW": "primary",
                "EXPOSE": "warning",
                "BAUGENEHMIGUNG_UND_EXPOSE": "warning",
                "BAUSTART_UND_ROHBAU": "warning",
                "ROHBAU_UND_INSTALLATIONEN": "warning",
                "BAUARBEITEN_ABGESCHLOSSEN": "success",
                "SCHLUESSELFERTIG": "success",
                "NEUBAU": "success",
                "ALTBAU": "success",
            };
            return (status) => {
                return statusVariants[status] != null ? statusVariants[status] : "primary";
            }
        },

        getFeaturedStatus() {
            return (realEstate) => {
                if (realEstate.advertise == null || realEstate.advertise === false)
                    return "Nicht inseriert";

                else if (realEstate.advertise === true && realEstate.featureStatus == null)
                    return "Einfaches Inserat";

                else (realEstate.featureStatus != null)
                {
                    if (realEstate.featureStatus.propertyPage === true)
                        return "Property page";
                    if (realEstate.featureStatus.searchPage === true)
                        return "Searchpage";
                    if (realEstate.featureStatus.homePage === true)
                        return "Homepage";

                }
                return "No info";
            }
        },
        getPrices() {
            return (realEstate) => {
                let prices = realEstate.value;
                if (prices.rentTotal != null)
                    return "Miete: €" + prices.rentTotal;
                else if (prices.operatingCost != null)
                    return "Miete: €" + (prices.rentNetto + prices.operatingCost);
                else if (prices.rentNetto != null)
                    return "Miete: €" + prices.rentNetto;
                else if (prices.priceBrutto != null)
                    return "Kaufpreis: €" + prices.priceBrutto;
                else if (prices.price != null)
                    return "Kaufpreis: €" + prices.price;
                else return ""
            }
        },
        getFullAddress() {
            return (realEstate) => {
                if (realEstate == null) return "";
                let address = "";
                if (realEstate.address != null) {
                    address += realEstate.address;
                }
                if (realEstate.house != null) {
                    address += (address !== "" ? "," : "") + realEstate.house;
                }
                if (realEstate.apt != null) {
                    address += (address !== "" ? "/" : "") + realEstate.apt;
                }
                if (realEstate.city != null) {
                    address += (address !== "" ? "," : "") + realEstate.city;
                }
                return address;
            }
        }
    },
    actions: {
        copyLinkSuccess(actions, id) {
            actions.dispatch('notifications/success',
                {title: 'Success', message: 'Link ' + id + ' copied'},
                {root: true});

        },
        getDocumentRealEstate(actions, {fileId, realEstateId}) {
            let $this = this;
            return new Promise(function (resolve, reject) {
                $this.$api.downloadApi().get(`/real-estate/owner/contract/download/realEstate/file`, {
                    params: {
                        id: fileId,
                        realEstateId
                    }
                })
                    .then(response => {
                        let contentType = response.headers["content-type"] || "";
                        let contentDisposition = response.headers["Content-disposition"] || "";
                        let fileName = "file";
                        if (contentDisposition.includes("filename=")) {
                            fileName = contentDisposition.substr(contentDisposition.toString().indexOf("filename=") + 9);
                        }
                        if (fileName === "file" && contentType.includes("filename=")) {
                            fileName = contentType.substr(contentType.toString().indexOf("filename=") + 9)
                        }

                        resolve({data: new Blob([response.data], {type: contentType}), fileName, contentType});
                    }, error => {
                        reject(error);
                    });
            });
        },
        getById(actions, id) {
            let $this = this;
            return new Promise(function (resolve, reject) {
                $this.$api.clientApi().get('/real-estate/getById/' + id)
                    .then(response => {
                        resolve(response.data.objects["RealEstate"])
                    })
                    .catch(error => reject(error))
            });
        },
        getAll() {
            let $this = this;
            return new Promise(function (resolve, reject) {
                $this.$api.clientApi().get('/real-estate/owner/contract/all')
                    .then(response => {
                        resolve(response.data.objects)
                    })
                    .catch(error => reject(error))
            });
        },
        getAllProperties() {
            let $this = this;
            return new Promise(function (resolve, reject) {
                $this.$api.clientApi().get('/real-estate/owner/contract/allProperties')
                    .then(response => {
                        resolve(response.data.objects)
                    })
                    .catch(error => reject(error))
            });
        },

    }
}
