<template>
  <span></span>
</template>
<script>
// eslint-disable-next-line no-unused-vars
import ToastificationContent from './ToastificationContent'

export default {
  name: 'NotificationListener',
  data:()=>({
    listener(){}
  }),
  created() {
    let $this = this;
    this.$root.$on('crm::notify',this.listener = notification => {
      $this.showToast(notification);
    })
  },
  beforeDestroy() {
    this.$root.$off('crm::notify',this.listener );
  },
  methods:{
    showToast(message) {
      console.log("notify",message)
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.getText(message.title),
          icon:message.icon,
          text: this.getText(message.message),
          variant:message.variant,
          timeout: 5000000
        },
      })
    },
    getText(message){
      if(message != null && typeof message !== 'string' && message.text != null){
        let args = message.arguments || [];
        return this.$t(message.text).format(args.map(s=>this.$t(s)));
      }
      return this.$t(message);
    },
  }
}
</script>
