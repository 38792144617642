<template>
  <div >
    <b-navbar toggleable="lg" class="border-bottom  text-primary" type="dark" variant="secondary">
      <b-navbar-brand class="" v-bind:to="'/'">
        <a class="brand cursor-pointer">

          <img src="@/assets/images/logo.png" alt="logo" class="th-logo"/>
        </a>
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav >
        <b-navbar-nav style="color: whitesmoke!important;">
          <b-nav-item v-bind:to="'/monetary'">{{ $t('monetaryOperations') }}</b-nav-item>
        </b-navbar-nav >
        <b-navbar-nav style="color: whitesmoke!important;" class="ml-2">
          <b-nav-item v-bind:to="'/calendar'">{{ $t('calendar') }}</b-nav-item>
        </b-navbar-nav >
        <b-navbar-nav style="color: whitesmoke!important;" class="ml-2">
          <b-nav-item v-bind:to="'/realestates'">{{ $t('myProperties') }}</b-nav-item>
        </b-navbar-nav>
        <b-navbar-nav style="color: whitesmoke!important;" class="ml-2">
          <b-nav-item v-bind:to="'/contract'">{{ $t('myContracts') }}</b-nav-item>
        </b-navbar-nav>
        <b-navbar-nav style="color: whitesmoke!important;" class="ml-2">
          <b-nav-item v-bind:to="'/prices'">{{ $t('priceList') }}</b-nav-item>
        </b-navbar-nav>


        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">

          <hostaway-notification-dropdown />



          <b-nav-item-dropdown right>
            <template #button-content>
              <country-flag v-bind:country='languages.options[languages.selected].flag' size='small'/>
              {{ languages.options[languages.selected].label }}
            </template>
            <b-dropdown-item href="#" v-for="lang in languages.options" v-bind:key="lang.flag"
                             @click="()=>onLanguagesChange(lang.code)">
              <country-flag v-bind:country='lang.flag' size='small'/>
              {{ lang.label }}
            </b-dropdown-item>
          </b-nav-item-dropdown>

          <b-nav-item-dropdown right>
            <!-- Using 'button-content' slot -->
            <template #button-content>
              <p class="m-0 d-inline-block">
                <b-avatar class="mx-1" size="sm" variant="primary"/>
                {{ clientProfile.clientFullname }}
              </p>
            </template>
            <b-dropdown-item href="#" @click="onLogout">{{ $t("account.logout") }}</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import HostawayNotificationDropdown from "@/components/widgets/HostawayNotificationDropdown";

export default {
  name: "layoutHeader",
  components:{    HostawayNotificationDropdown},
  data: () => ({
    languages: {
      options: {"en": {label: "English", flag: "gb", code: "en"}, "de": {label: "Deutsch", flag: "de", code: "de"}},
      selected: "en",
    }
  }),
  created() {
    this.onLanguagesChange(localStorage.lang || "en")
  },
  computed: {
    ...mapGetters('client', ['clientProfile'])
  },
  methods: {
    ...mapMutations('client', ['logout']),
    onLanguagesChange(code) {
      localStorage.lang = code;
      this.$i18n.locale = code;
      this.languages.selected = code
      this.$root.$emit("bv::refresh::table");
    },
    onLogout() {
      this.logout();
      this.$router.push("/auth/login")
    }
  }
}
</script>

<style scoped>
.th-logo {
  width: 100px;
  margin-top: -40px;
}

.th-logo-sm {
  margin-top: -11px;
  width: 68px;
  margin-left: -9px;
}

</style>
