import {vue} from '@/main'


const notifications = {
    namespaced: true,
    actions: {
        success(actions, {title, message, sticky, audio}) {
            let params = {title: title, message, variant: "success", icon: 'CheckIcon', data: {}, audio, sticky };
            vue.$root.$emit("crm::notify",params)
        },
        error(actions, {title, message, sticky, audio}) {
            let params = { title: title, message, variant: "danger",icon: 'XIcon', data: {}, sticky, audio };
            vue.$root.$emit("crm::notify", params)
        },
        $t(actions, {title, message, sticky, audio}) {
            let params = { title: title, message, variant: "primary",icon: 'InfoIcon', sticky, audio };
            vue.$root.$emit("crm::notify", params)
        },
        reminder(actions, {title, message, sticky, audio, audioType}) {
            let params = { title: title, message, variant: "warning", icon:'BellIcon', sticky, audio, audioType };
            vue.$root.notify(params);
        },
    }
}

export default notifications;
