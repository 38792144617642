export default {
    namespaced: true,

    getters: {},
    actions: {

        getCustomDocument(actions, {fileId,contractId}){
            let $this = this;
            return new Promise(function (resolve, reject) {
                $this.$api.downloadApi().get(`/real-estate/owner/contract/download/file`,{params: {id:fileId,contractId}})
                    .then(response => {
                        console.log("downloadFile", response)
                        let contentType = response.headers["content-type"];
                        let fileName = "file";
                        if (contentType.includes("filename="))
                            fileName = contentType.substr(contentType.toString().indexOf("filename=") + 9)
                        resolve({data:response.data, fileName});
                    }, error => {
                        reject(error);
                    });
            });
        },
    }
}
