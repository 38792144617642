export default {
  namespaced: true,
  state: () => ({
    loggedIn: false,
    profile: {},
  }),
  getters: {
    loggedIn(state) {
      return state.loggedIn;
    },
    clientAddress(state) {
      if (state['profile'] === null)
        return {}
      let profile = state['profile'];
      return {address: profile['address'], city: profile['city'], country: profile['country'], zip: profile['zip']}
    },
    clientProfile(state) {
      if (state.loggedIn === false)
        return {};
      return state.profile;
    },
    clientFullName(state){
      if (state.loggedIn === false)
        return "";
      let name = state.profile.firstname;
      return state.profile.lastname != null ? name + " " + state.profile.lastname : name;
    }
  },
  mutations: {
    updateData(state, data) {
      if (data['Profile'] != null) {
        state['profile'] = data['Profile'];
        state['loggedIn'] = true;
      }
      if (data['uniqueId'] != null) {
        localStorage.uniqueId = data['uniqueId'];
      }
      if (data['Bearer'] != null) {
        localStorage.Bearer = data['Bearer'];
      }
    },
    logout(state){
      localStorage.setItem("Bearer",null);
      localStorage.setItem("uniqueId",null);
      state['profile'] = null;
      state['loggedIn'] = null;
    }
  },
  actions: {
    checkConnection({commit},to) {
      let $this = this;
      to = to || {};
      return new Promise(function (resolve, reject) {
        $this.$api.clientApi().get('/profile')
          .then(response => {
            commit('updateData', response.data.objects)
            resolve(to.fullPath)
          })
          .catch(error => reject(error))
      });
    },
    login({commit}, {username, password}) {
      let $this = this;
      return new Promise(function (resolve, reject) {
        $this.$api.clientApi().post('/login', null,
          {params: {username, password}})
          .then(response => {
            commit('updateData', response.data.objects)
            resolve()
          })
          .catch(error => reject(error.response.data.message))

      });
    },
    register({commit}, client) {
      let $this = this;
      return new Promise(function (resolve, reject) {
        $this.$api.branchApi().post('/client/add', client)
          .then(response => {
            commit('updateData', response.data.objects)
            resolve()
          })
          .catch(error => reject(error))

      });
    },
    recoveryPassword(actions, email) {
      let $this = this;
      return new Promise(function (resolve, reject) {
        $this.$api.branchApi().get('/client/password', {params: {email}})
          .then(response => {
            resolve(response  )
          })
          .catch(error => reject(error.response.data.message))

      });
    }
  }
}
