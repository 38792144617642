<template>
  <b-nav-item-dropdown
      style="width: 100%!important;"
      ref="notificationDropdown"
      class="dropdown-notification-wide mr-30"
      menu-class="dropdown-menu-media"
      right
  >

    <template #button-content>
      <div>
        <feather-icon badge-classes="bg-danger" class="text-body" icon="SendIcon" size="21"/>
        <span class="badge badge-up badge-pill bg-primary" v-if="messages!=null">{{messages.length>0?messages.length:0}}</span>
      </div>
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex" >
        <h4 class="notification-title mb-0 mr-auto">
          {{ $t('messages') }}
        </h4>
        <b-badge
            pill
            variant="light-primary"
        >
          {{messages!=null?messages.length:0}} {{ $t('New') }}
        </b-badge>
      </div>
    </li>

    <div class="scrollable-container media-list scroll-area" v-b-scrollspy>
      <div v-for="(message, index) in messages" :key="index">
        <b-card
            class="mb-1"
            @click="goToContract(message)"
            style="cursor: pointer;"
            :bg-variant="index % 2 === 0 ? 'primary' : 'secondary'"
        >
          <div class="notification-content">
            <b-badge :variant="index % 2 === 0 ? 'secondary' : 'primary'">Name: {{ message.customerName }}</b-badge>
            <p class="mt-1" style="color: black;font-weight: bold">{{ message.propertyAddress }}</p>
            <!-- Add other message details here -->
          </div>
        </b-card>
      </div>
    </div>

  </b-nav-item-dropdown>
</template>

<script>
import { BNavItemDropdown, BBadge, BCard } from 'bootstrap-vue';
import { mapActions } from "vuex";

export default {
  name: 'HostawayNotificationDropdown',
  components: {
    BNavItemDropdown,
    BBadge,
    BCard
  },
  data() {
    return {
      messages: [],
      error: null,
      refreshInterval: null, // To store the setInterval reference
      dropdownVisible: false, // Track dropdown visibility
    };
  },
  created() {
    this.refresh(); // Initial call to refresh messages
    this.startAutoRefresh(); // Start automatic refresh
  },
  beforeDestroy() {
    this.stopAutoRefresh(); // Clear interval when component is destroyed
  },
  methods: {
    ...mapActions("hostaway", ["getUnseenMessages"]), // Map your actions here

    // Method to navigate to contract
    goToContract(message) {
      this.$refs.notificationDropdown.hide();
      this.refresh(); // Initial call to refresh messages
      this.$router.push("/contract/view/" + message.contractId)
    },

    // Refresh messages
    refresh() {
      this.getUnseenMessages()
          .then(this.onRetrieve)
          .catch(error => {
            this.error = error;
            console.error('Error fetching messages:', error);
          });
    },

    // Process retrieved data
    onRetrieve(data) {
      console.log('Messages retrieved:', data.messages);
      this.messages = data.messages || [];
    },

    // Start auto-refresh every 30 seconds
    startAutoRefresh() {
      this.refreshInterval = setInterval(() => {
        this.refresh();
      }, 30000); // 30 seconds in milliseconds
    },

    // Stop auto-refresh
    stopAutoRefresh() {
      clearInterval(this.refreshInterval);
    },

    // Event handler when dropdown is hidden
    dropdownHidden() {
      this.dropdownVisible = false;
    },
  }
};
</script>

<style>
/* Add any necessary styles for your scrollable content here */
.scrollable-container {
  max-height: 500px; /* Adjust as needed */
  overflow-y: auto;
}

.dropdown-notification-wide .dropdown-menu {
  width: 400px; /* Adjust the width as needed */
  background-color: rgba(245, 241, 232, 0.89);
}

/* Adjust the width of the dropdown toggle button */
.dropdown-notification-wide .dropdown-toggle {
  width: 100%; /* Make the toggle button wider */
  text-align: left; /* Align text to the left within the button */
  /* Add any other necessary styles for the toggle button */
}
</style>
